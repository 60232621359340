import React, { useEffect } from "react";
import {
  Text,
  HStack,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  Box,
  StatusBar,
} from "native-base";
import { store, persistor } from "./src/redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { registerTranslation } from "react-native-paper-dates";
import AppNavigation from "./src/navigators/AppNavigation";
import { GlobalConstants } from "./src/constants/constants";
import { Analytics, PageHit } from "expo-analytics";

// Define the config
const config = {
  useSystemColorMode: false,
  initialColorMode: "dark",
};

// extend the theme
export const theme = extendTheme({ config });

export default function App() {
  useEffect(() => {
    const analytics = new Analytics(GlobalConstants.googleTrackingId);
    analytics
      .hit(new PageHit("Home"))
      .then(() => console.log("success"))
      .catch((e) => console.log(e.message));

    return () => {
      // Add any necessary cleanup logic
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NativeBaseProvider>
          <Box
            _dark={{ bg: "blueGray.800" }}
            _light={{ bg: "muted.100" }}
            flex={1}
            safeAreaTop
          >
            <StatusBar style="auto" />
            <AppNavigation />
          </Box>
        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  );
}

// Color Switch Component
function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2} alignItems="center">
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        aria-label={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}

registerTranslation("en-GB", {
  save: "Save",
  selectSingle: "Select Date",
  selectMultiple: "Select Dates",
  selectRange: "Select Range",
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: "Day is not allowed",
  previous: "Previous",
  next: "Next",
  typeInDate: "Type in date",
  pickDateFromCalendar: "Pick date from calendar",
  close: "Close",
});
