import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  Icon,
  Image,
  Spinner,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import TicksView from "../../components/TicksView";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { Pressable } from "react-native";
import LoadFonts from "../../components/LoadFonts";
import FeedCardForCitizen from "../../components/FeedCardForCitizen";

const CitizenMainPage = () => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [cityGovBtnTextColor, setCityGovBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [businessBtnTextColor, setBusinessBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [eventsBtnTextColor, setEventsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [openingsBtnTextColor, setOpeningsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [dealsBtnTextColor, setDealsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [adsBtnTextColor, setAdsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [savedBtnTextColor, setSavedBtnTextColor] = useState(
    GlobalStyles.colors.white
  );
  const [sharedBtnTextColor, setSharedBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [ticks, setTicks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  const [isEventsEnabled, setIsEventsEnabled] = useState(true);
  const [isOpeningsEnabled, setIsOpeningsEnabled] = useState(true);
  const [isDealsEnabled, setIsDealsEnabled] = useState(true);

  const [isShowEventsText, setIsShowEventsText] = useState(false);

  const [selectedPost, setSelectedPost] = useState({});
  const [feedType, setFeedType] = useState(0);
  const [friends, setFriends] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [page, setPage] = useState();
  const [isSavedFeeds, setIsSavedFeeds] = useState(false);
  const [isShareFeeds, setIsShareFeeds] = useState(false);

  function changeColor(btnType) {
    if (btnType === "Business") {
      setBusinessBtnTextColor(GlobalStyles.colors.mustard);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    } else if (btnType === "CityGov") {
      setBusinessBtnTextColor(GlobalStyles.colors.blue);
      setCityGovBtnTextColor(GlobalStyles.colors.mustard);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    } else if (btnType === "Events") {
      setBusinessBtnTextColor(GlobalStyles.colors.blue);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.mustard);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(true);
    } else if (btnType === "Openings") {
      setBusinessBtnTextColor(GlobalStyles.colors.blue);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.mustard);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    } else if (btnType === "Deals") {
      setBusinessBtnTextColor(GlobalStyles.colors.blue);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.mustard);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    } else if (btnType === "Ads") {
      setBusinessBtnTextColor(GlobalStyles.colors.blue);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.mustard);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    } else if (btnType === "Saved") {
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.mustard);
    } else if (btnType === "Shared") {
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.mustard);
    } else {
      setBusinessBtnTextColor(GlobalStyles.colors.mustard);
      setCityGovBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setOpeningsBtnTextColor(GlobalStyles.colors.blue);
      setAdsBtnTextColor(GlobalStyles.colors.blue);
      setDealsBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setSharedBtnTextColor(GlobalStyles.colors.blue);
      setIsShowEventsText(false);
    }
  }

  const getSettings = async () => {
    if (user.user_category == GlobalConstants.userCategories.citizen.id) {
      try {
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}users/settings`
        );
        if (resp.data.status) {
          setIsEventsEnabled(resp.data.data.is_events_display);
          setIsOpeningsEnabled(resp.data.data.is_openings_display);
          setIsDealsEnabled(resp.data.data.is_deals_display);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Failed to get settings!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const feedsFirstPage = async (type) => {
    setIsSavedFeeds(false);
    setIsShareFeeds(false);
    setTicks([]);
    setIsLoading(true);
    setFeedType(type);
    let data = await getTicksByTypeId(type, 1);
    setTicks(data);
    setPage(2);
    setIsLoading(false);
  };

  const feedsNextPage = async () => {
    setIsLoadMoreLoading(true);
    let data = await getTicksByTypeId(feedType, page);
    setTicks([...ticks, ...data]);
    setPage(page + 1);
    setIsLoadMoreLoading(false);
  };

  const getTicksByTypeId = async (type, page, offSet = 10) => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}v1/users/feed?type=` +
          type +
          `&page=` +
          page +
          `&limit=` +
          offSet
      );
      if (resp.data.status) {
        var data = resp.data.data;
        data.forEach((element) => {
          element.savedAllowed = true;
        });

        if (data.length == offSet) {
          setShowLoadMore(true);
        } else {
          setShowLoadMore(false);
        }
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async (data) => {
    console.log(data);
    setSelectedPost(data);
    if (data.is_saved) {
      await unSavePost(data.id);
    } else {
      await savePost(data.id);
    }
    if (savedBtnTextColor == GlobalStyles.colors.mustard) {
      await getSavedPost(false);
    }
  };

  const savePost = async (postId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts/save`,
        {
          post_id: postId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Post saved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save post!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unSavePost = async (postId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts/unsave`,
        {
          post_id: postId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Post unsaved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unsave post!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSavedPost = async (isLoading = true) => {
    setIsSavedFeeds(true);
    setIsLoading(isLoading);
    setTicks([]);
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}v1/users/saved-feed?type=` +
          feedType
      );
      if (resp.data.status) {
        var feedArray = resp.data.data;
        feedArray.forEach((element) => {
          element.is_saved = true;
          element.savedAllowed = true;
        });
        setTicks(feedArray);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSharedPost = async (isLoading = true) => {
    setIsShareFeeds(true);
    setIsLoading(isLoading);
    setTicks([]);
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}friend/shared/post?type=` + feedType
      );
      if (resp.data.status) {
        var feedArray = resp.data.data;
        feedArray.forEach((element) => {
          element.savedAllowed = true;
        });
        setTicks(feedArray);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFriends = async () => {
    if (user.user_category == GlobalConstants.userCategories.citizen.id) {
      try {
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}/friend`
        );
        if (resp.data.status) {
          setFriends(resp.data.data);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Failed to get Invited Friends!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    changeColor("Business");
    await feedsFirstPage(GlobalConstants.feedTypes.business.id);
    await getSettings();
    await getFriends();
    LoadFonts();
  }, []);

  return (
    <>
      <ScrollView>
        <Center>
          <Box safeAreaTop bg="white" />
          <VStack
            bg="#F5F5F5"
            px="1"
            py="3"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            maxW="900"
          >
            <Stack
              alignItems="center"
              flexDirection={window?.innerWidth < 415 ? "column" : "row"}
            >
              <Stack
                w={[350, 350, 350]}
                justifyContent="center"
                flexDirection={"row"}
              >
                <Button
                  size={"lg"}
                  variant={"solid"}
                  mt="2"
                  mx="1"
                  px="2"
                  bg={GlobalStyles.colors.white}
                  borderColor={businessBtnTextColor}
                  borderWidth={1}
                  borderRadius={10}
                  leftIcon={
                    <Image
                      source={require("../../../assets/businessIcon.png")}
                      alt="Alternate Text"
                      size="6"
                    />
                    // <MaterialIcons
                    //   name="business"
                    //   size={24}
                    //   color={businessBtnTextColor}
                    // />
                  }
                  onPress={() => {
                    changeColor("Business");
                    feedsFirstPage(GlobalConstants.feedTypes.business.id);
                  }}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={GlobalStyles.colors.blue}
                  >
                    Business
                  </Text>
                </Button>
                <Button
                  size={"lg"}
                  variant={"solid"}
                  mt="2"
                  mx="1"
                  px="2"
                  bg={GlobalStyles.colors.white}
                  borderColor={cityGovBtnTextColor}
                  borderWidth={1}
                  borderRadius={10}
                  leftIcon={
                    <Image
                      source={require("../../../assets/citygovIcon.png")}
                      alt="Alternate Text"
                      size="6"
                    />
                    // <MaterialIcons
                    //   name="location-city"
                    //   size={24}
                    //   color={cityGovBtnTextColor}
                    // />
                  }
                  onPress={() => {
                    changeColor("CityGov");
                    feedsFirstPage(GlobalConstants.feedTypes.cityGovernment.id);
                  }}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={GlobalStyles.colors.blue}
                  >
                    City Gov
                  </Text>
                </Button>
                <Button
                  size={"lg"}
                  variant={"solid"}
                  mt="2"
                  mx="1"
                  px="2"
                  bg={GlobalStyles.colors.white}
                  borderColor={eventsBtnTextColor}
                  borderWidth={1}
                  borderRadius={10}
                  leftIcon={
                    <Image
                      source={require("../../../assets/eventIcon.png")}
                      alt="Alternate Text"
                      size="7"
                    />
                    // <MaterialIcons
                    //   name="event"
                    //   size={24}
                    //   color={eventsBtnTextColor}
                    // />
                  }
                  onPress={() => {
                    changeColor("Events");
                    feedsFirstPage(GlobalConstants.feedTypes.events.id);
                  }}
                  isDisabled={isEventsEnabled ? false : true}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={GlobalStyles.colors.blue}
                  >
                    Events
                  </Text>
                </Button>
              </Stack>
              <Stack
                w={[310, 310, 310]}
                justifyContent="center"
                flexDirection={"row"}
              >
                <Button
                  size={"lg"}
                  variant={"solid"}
                  mt="2"
                  mx="1"
                  px="2"
                  bg={GlobalStyles.colors.white}
                  borderColor={openingsBtnTextColor}
                  borderWidth={1}
                  borderRadius={10}
                  leftIcon={
                    <Image
                      source={require("../../../assets/openingIcon.png")}
                      alt="Alternate Text"
                      size="5"
                    />
                    // <MaterialIcons
                    //   name="meeting-room"
                    //   size={24}
                    //   color={openingsBtnTextColor}
                    // />
                  }
                  onPress={() => {
                    changeColor("Openings");
                    feedsFirstPage(GlobalConstants.feedTypes.openings.id);
                  }}
                  isDisabled={isOpeningsEnabled ? false : true}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={GlobalStyles.colors.blue}
                  >
                    Openings
                  </Text>
                </Button>

                <Button
                  size={"lg"}
                  variant={"solid"}
                  mt="2"
                  mx="1"
                  px="2"
                  bg={GlobalStyles.colors.white}
                  borderColor={dealsBtnTextColor}
                  borderWidth={1}
                  borderRadius={10}
                  leftIcon={
                    <Image
                      source={require("../../../assets/dealsIcon.png")}
                      alt="Alternate Text"
                      size="5"
                    />
                  }
                  onPress={() => {
                    changeColor("Deals");
                    feedsFirstPage(GlobalConstants.feedTypes.deals.id);
                  }}
                  isDisabled={isDealsEnabled ? false : true}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={GlobalStyles.colors.blue}
                  >
                    Deals
                  </Text>
                </Button>

                <Pressable
                  onPress={() => {
                    navigation.navigate("CitizenAdsPage");
                  }}
                >
                  <Image
                    mt="4"
                    mx="5"
                    source={require("../../../assets/adButton.png")}
                    alt="Alternate Text"
                    size="xs"
                  />
                </Pressable>
              </Stack>
            </Stack>
          </VStack>

          <HStack
            bg="#F5F5F5"
            justifyContent="space-between"
            w="100%"
            maxW="900"
          >
            <HStack
              bg="#F5F5F5"
              justifyContent="space-between"
              w="100%"
              maxW={[
                user.user_category == GlobalConstants.userCategories.citizen.id
                  ? 75
                  : 0,
                130,
                130,
              ]}
            >
              {user.user_category ==
                GlobalConstants.userCategories.citizen.id && (
                <VStack>
                  <Button
                    w={70}
                    size={"sm"}
                    variant={"solid"}
                    mt="2"
                    mx={5}
                    bg={GlobalStyles.colors.white}
                    borderColor={savedBtnTextColor}
                    borderWidth={1}
                    borderRadius={10}
                    onPress={() => {
                      changeColor("Saved");
                      getSavedPost();
                    }}
                  >
                    <Text
                      fontSize={"lg"}
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={GlobalStyles.colors.blue}
                    >
                      Saved
                    </Text>
                  </Button>
                  <Button
                    w={70}
                    size={"sm"}
                    variant={"solid"}
                    mt="2"
                    mx={5}
                    bg={GlobalStyles.colors.white}
                    borderColor={sharedBtnTextColor}
                    borderWidth={1}
                    borderRadius={10}
                    onPress={() => {
                      changeColor("Shared");
                      getSharedPost();
                    }}
                  >
                    <Text
                      fontSize={"lg"}
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={GlobalStyles.colors.blue}
                    >
                      Shared
                    </Text>
                  </Button>
                </VStack>
              )}
            </HStack>
            <HStack
              bg="#F5F5F5"
              justifyContent="space-between"
              w="100%"
              maxW={[250, 440, 640]}
            >
              <VStack>
                {isShowEventsText ? (
                  <>
                    <Stack w={[300, 450, 610]} alignItems="center">
                      <Text
                        // alignSelf={"flex-start"}
                        fontFamily={"Petemoss"}
                        fontSize={40}
                        // fontWeight={"bold"}
                        color={GlobalStyles.colors.blue}
                      >
                        Upcoming Events
                      </Text>
                    </Stack>
                  </>
                ) : (
                  <></>
                )}

                {isLoading ? (
                  <>
                    <Stack w={[300, 450, 610]} alignItems="center">
                      <Spinner
                        color={GlobalStyles.colors.mustard}
                        m={10}
                        size="lg"
                        accessibilityLabel="Loading posts"
                      />
                    </Stack>
                  </>
                ) : ticks ? (
                  <>
                    <ScrollView
                      w={[260, 360, 610]}
                      showsVerticalScrollIndicator={false}
                    >
                      <VStack alignItems="center">
                        <Stack
                          justifyContent="center"
                          flexDirection={"row"}
                          space={3}
                          flexWrap="wrap"
                          m={1}
                        >
                          {ticks.map((x, index) => {
                            return (
                              <FeedCardForCitizen
                                data={x}
                                setSelectedPost={onClick}
                                friends={friends}
                              />
                            );
                          })}
                        </Stack>
                        {!isSavedFeeds && !isShareFeeds && showLoadMore ? (
                          <>
                            <Button
                              my={5}
                              isLoading={isLoadMoreLoading}
                              size="sm"
                              variant="outline"
                              onPress={feedsNextPage}
                            >
                              Load More
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </VStack>
                    </ScrollView>
                  </>
                ) : (
                  <></>
                )}
              </VStack>
            </HStack>
            <HStack
              bg="#F5F5F5"
              justifyContent="space-between"
              w="100%"
              maxW={[0, 130, 130]}
            ></HStack>
          </HStack>
        </Center>
      </ScrollView>
    </>
  );
};

export default CitizenMainPage;
