import React, { useState, useEffect, useRef } from "react";
import {
  Center,
  ScrollView,
  Text,
  Image,
  HStack,
  Stack,
  Box,
  VStack,
  Button,
  Modal,
} from "native-base";
import { GlobalStyles } from "./../constants/styles";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { GlobalConstants } from "../constants/constants";
import BusinessMainPage from "./main/BusinessMainPage";
import CitizenMainPage from "./main/CitizenMainPage";
import CityGovMainPage from "./main/CityGovMainPage";
import EventMainPage from "./main/EventMainPage";
import ChamberOfCommerceMainPage from "./main/ChamberOfCommerceMainPage";
import AdvertiserMainPage from "./main/AdvertiserMainPage";

function Home() {
  const navigation = useNavigation();

  let user = useSelector((state) => state.user.user);

  const [modalVisible, setModalVisible] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(async () => {
    console.log(window?.innerHeight);
    if (user && user.is_new_user) {
      navigation.navigate("Settings");
    }
  }, []);

  return (
    <>
      {user && user.user_category == GlobalConstants.userCategories.guest.id ? (
        <CitizenMainPage />
      ) : user &&
        user.user_category == GlobalConstants.userCategories.citizen.id ? (
        <CitizenMainPage />
      ) : user &&
        user.user_category == GlobalConstants.userCategories.business.id ? (
        <BusinessMainPage />
      ) : user &&
        user.user_category ==
          GlobalConstants.userCategories.cityGovernment.id ? (
        <CityGovMainPage />
      ) : user &&
        user.user_category ==
          GlobalConstants.userCategories.chamberOfCommerce.id ? (
        <ChamberOfCommerceMainPage />
      ) : user &&
        user.user_category == GlobalConstants.userCategories.advertiser.id ? (
        <AdvertiserMainPage />
      ) : user &&
        user.user_category ==
          GlobalConstants.userCategories.eventOrganizer.id ? (
        <EventMainPage />
      ) : (
        <ScrollView>
          <Center bg={GlobalStyles.colors.white}>
            <Box safeAreaTop bg="white" />
            <VStack
              justifyContent="space-between"
              alignItems="center"
              w={[350, 450, 900]}
              h={[
                window?.innerHeight - 70,
                window?.innerHeight - 70,
                window?.innerHeight - 70,
              ]}
            >
              <Stack
                mt={window?.innerHeight > 700 ? 10 : 5}
                bg={GlobalStyles.colors.white}
                justifyContent="space-between"
                alignItems="center"
                flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              >
                <Center w={[400, 400, 450]}>
                  <Image
                    source={require("../../assets/HomeImage2.jpeg")}
                    alt="Alternate Text"
                    resizeMode="contain"
                    h={[280, 280, 280]}
                    w={[350, 400, 450]}
                  />
                </Center>
                <Center w={[400, 400, 450]}>
                  <Text
                    mt={5}
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="xl"
                    fontWeight="bold"
                    color={GlobalStyles.colors.mustard}
                    w={[320, 320, 320]}
                  >
                    Responsible Social Media
                  </Text>
                  <Text
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="md"
                    fontWeight="bold"
                    color={GlobalStyles.colors.blue}
                    w={[320, 320, 320]}
                    my={3}
                  >
                    Current social media is a cancer for brain.
                  </Text>
                  <Text
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="md"
                    color={GlobalStyles.colors.blue}
                    w={[320, 320, 320]}
                  >
                    Help us give world a clean new social media alternative with
                    no addiction, no information overload, no hate speech, no
                    fake content and respect for privacy.
                  </Text>
                </Center>
              </Stack>

              <Stack
                bg={GlobalStyles.colors.white}
                justifyContent="space-between"
                alignItems="center"
                flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              >
                <Center w={[400, 400, 450]}>
                  <Text
                    mt={5}
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="xl"
                    fontWeight="bold"
                    color={GlobalStyles.colors.mustard}
                    w={[320, 320, 320]}
                  >
                    Town Ticks: Your City Pulse
                  </Text>
                  <Text
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="md"
                    fontWeight="bold"
                    color={GlobalStyles.colors.blue}
                    w={[320, 320, 320]}
                    my={3}
                  >
                    Better & Active Urban Life
                  </Text>
                  <Text
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize="md"
                    color={GlobalStyles.colors.blue}
                    w={[320, 320, 320]}
                  >
                    Town Ticks focus your attention to where you live. Town
                    Ticks updates in real time about new openings, Gov
                    announcements, deals, events and messages from your fav
                    business.
                  </Text>
                </Center>

                <Center w={[400, 400, 450]}>
                  <Image
                    source={require("../../assets/HomeImage1.jpeg")}
                    alt="Alternate Text"
                    resizeMode="contain"
                    h={[280, 280, 280]}
                    w={[350, 400, 450]}
                  />
                </Center>
              </Stack>

              <Stack
                bg={GlobalStyles.colors.white}
                justifyContent="space-between"
                alignItems="center"
                flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              >
                <Center>
                  <Text
                    fontSize={"xs"}
                    color={GlobalStyles.colors.blue}
                    fontFamily={GlobalStyles.text.fontFamily}
                  >
                    Town Ticks is a product of Novelma Inc.{" "}
                    <Button
                      size="sm"
                      variant="link"
                      onPress={() => {
                        setModalVisible(!modalVisible);
                      }}
                    >
                      Contact Us
                    </Button>
                  </Text>
                </Center>
              </Stack>
            </VStack>
          </Center>
        </ScrollView>
      )}

      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Body>
            <Text>
              To contact or any feedback about Town Ticks, write to
              <Text color={GlobalStyles.colors.blue}>
                {" "}
                support.townticks@novelma.com
              </Text>
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                Close
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default Home;
