import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  FormControl,
  Select,
  Heading,
  FlatList,
  Avatar,
  Spacer,
  Tooltip,
  Switch,
  Input,
  Icon,
  Image,
} from "native-base";
import { SimpleLineIcons, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { GlobalStyles } from "../../constants/styles";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ChangePassword from "../../components/ChangePassword";
import { truncateString, validEmail } from "../../utils/general";
import SetUser from "../../redux/user/SetUser";

const CitizenSettingsPage = () => {
  const dispatch = useDispatch();
  let session = useSelector((state) => state.user);
  console.log(session);
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [selectedCity, setSelectedCity] = useState();

  const [cityBtnTextColor, setCityBtnTextColor] = useState(
    GlobalStyles.colors.mustard
  );
  const [followedBusinessBtnTextColor, setFollowedBusinessBtnTextColor] =
    useState(GlobalStyles.colors.blue);

  const [searchAndFollowBtnTextColor, setSearchAndFollowBtnTextColor] =
    useState(GlobalStyles.colors.blue);
  const [eventsBtnTextColor, setEventsBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );
  const [categoryBtnTextColor, setCategoryBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );
  const [friendsBtnTextColor, setFriendsBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );

  const [isCitySelected, setIsCitySelected] = useState(true);
  const [isFollowedBusinessSelected, setIsFollowedBusinessSelected] =
    useState(false);
  const [isSearchAndFollowSelected, setIsSearchAndFollowSelected] =
    useState(false);
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [changePasswordSelected, setChangePasswordSelected] = useState(false);
  const [friendsSelected, setFriendsSelected] = useState(false);

  const [isEventsEnabled, setIsEventsEnabled] = useState(false);
  const [isOpeningsEnabled, setIsOpeningsEnabled] = useState(false);
  const [isDealsEnabled, setIsDealsEnabled] = useState(false);

  const [cities, setCities] = useState([]);

  const [noSearchBusinessResultText, setNoSearchBusinessResultText] =
    useState("");
  const [unfollowBusinesses, setUnfollowBusinesses] = useState([]);
  const [followedBusinesses, setFollowedBusinesses] = useState([]);

  const [businessSelectedId, setBusinessSelectedId] = useState();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownFilter, setIsDropdownFilter] = useState(false);

  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessSubCategories, setBusinessSubCategories] = useState([]);
  // const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);

  const [selectedCategory, setSelectedCategories] = useState("");
  const [selectedSubCategory, setSelectedSubCategories] = useState("");

  const [inviteEmail, setInviteEmail] = useState("");
  const [invitedFriends, setInvitedFriends] = useState([]);
  const [invitedFriendSelectedId, setInvitedFriendSelectedId] = useState();

  const renderUnfollowBusinessesFooter = () => {
    return (
      //Footer View with Load More button
      <Box>
        {isDropdownFilter ? (
          <></>
        ) : isSearching ? (
          <></>
        ) : (
          <Button
            isLoading={isLoading}
            size="sm"
            variant="outline"
            onPress={unfollowBusinessesNextPage}
          >
            Load More
          </Button>
        )}
      </Box>
    );
  };

  const unfollowBusinessesNextPage = async () => {
    setIsSearching(false);
    setIsLoading(true);
    let data = await getUnfollowBusinesses(page);
    //After the response increasing the offset for the next API call.
    setUnfollowBusinesses([...unfollowBusinesses, ...data]);
    setPage(page + 1);
    setIsLoading(false);
  };

  const unfollowBusinessesPage1 = async () => {
    setIsSearching(false);
    setIsLoading(true);
    setUnfollowBusinesses([]);
    let data = await getUnfollowBusinesses(1);
    setUnfollowBusinesses(data);
    setPage(2);
    setIsLoading(false);
  };

  const onSearchTextChange = async (keyword) => {
    if (keyword) {
      setSelectedCategories("");
      setSelectedSubCategories("");
      setIsDropdownFilter(false);
      setBusinessSubCategories([]);
      setIsSearching(true);
      setUnfollowBusinesses([]);
      let data = await getUnfollowBusinesses(1, 100, keyword);
      setUnfollowBusinesses(data);
      if (data.length) {
        setNoSearchBusinessResultText("");
      } else {
        setNoSearchBusinessResultText(
          "Business you are searching is not yet our partner."
        );
      }
    } else {
      await unfollowBusinessesPage1();
    }
  };

  const getUnfollowBusinesses = async (page, offSet = 50, text = "") => {
    let data = [];
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses?query=` +
          text +
          `&page=` +
          page +
          `&offSet=` +
          offSet
      );
      if (resp.data.status) {
        data = resp.data.data;
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getFollowedBusinesses = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/followed`
      );
      if (resp.data.status) {
        let data = resp.data.data;
        setFollowedBusinesses(data);
      } else {
        // toast.show({
        //   render: () => {
        //     return (
        //       <Toast
        //         color={GlobalStyles.colors.red}
        //         message={"Failed to get all businesses!!!"}
        //       />
        //     );
        //   },
        //   placement: "top",
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getAvatarChar(fullName) {
    let nameShortCode = "";
    if (fullName && fullName.split(" ").length > 1) {
      nameShortCode =
        fullName.split(" ")[0].charAt(0).toUpperCase() +
        fullName.split(" ")[1].charAt(0).toUpperCase();
    } else {
      nameShortCode = fullName.charAt(0).toUpperCase();
    }
    return nameShortCode;
  }

  function changeColor(btnType) {
    if (btnType === "FollowedBusiness") {
      setIsFollowedBusinessSelected(true);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.mustard);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "SearchAndFollow") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(true);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.mustard);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "City") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(true);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.mustard);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Events") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(true);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.mustard);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "ChangePassword") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(true);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.mustard);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Friends") {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(false);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(true);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.blue);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.mustard);
    } else {
      setIsFollowedBusinessSelected(false);
      setIsSearchAndFollowSelected(false);
      setIsCitySelected(true);
      setIsEventSelected(false);
      setChangePasswordSelected(false);
      setFriendsSelected(false);
      setFollowedBusinessBtnTextColor(GlobalStyles.colors.blue);
      setSearchAndFollowBtnTextColor(GlobalStyles.colors.blue);
      setCityBtnTextColor(GlobalStyles.colors.mustard);
      setEventsBtnTextColor(GlobalStyles.colors.blue);
      setCategoryBtnTextColor(GlobalStyles.colors.blue);
      setFriendsBtnTextColor(GlobalStyles.colors.blue);
    }
  }

  const getAllCities = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}cities`);
      if (resp.data.status) {
        setCities(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get cities!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCity = async () => {
    if (!selectedCity) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly select city!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }

    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/city`,
        { city_id: selectedCity }
      );
      if (resp.data.status) {
        dispatch(SetUser.action(null));
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully saved!!!"}
              />
            );
          },
          placement: "top",
        });

        // console.log("old token", session.user.token);

        const updatedSession = {
          ...session,
          user: {
            ...session.user,
            token: resp.data.data,
          },
        };
        // console.log("new token", updatedSession.user.token);

        dispatch(SetUser.action(updatedSession.user));
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save city!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEventAndOpeningsFlag = async (obj) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/settings`,
        obj
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully saved!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFollowBusiness = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/follow`,
        {
          following_id: id,
        }
      );
      if (resp.data.status) {
        updateBusinessStateOnFollowUnFollow(id, "follow");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully followed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to follow business!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUnfollowBusiness = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/unfollow`,
        {
          following_id: id,
        }
      );
      if (resp.data.status) {
        updateBusinessStateOnFollowUnFollow(id, "unfollow");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully unfollowed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unfollow business!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBusinessStateOnFollowUnFollow = async (id, type) => {
    let tempBus = [];
    if (type == "follow") {
      tempBus = unfollowBusinesses;
      tempBus = tempBus.filter((obj) => {
        return obj.id !== id;
      });
      setUnfollowBusinesses(tempBus);
      setBusinessSelectedId(id);
    } else {
      tempBus = followedBusinesses;
      tempBus = tempBus.filter((obj) => {
        return obj.id !== id;
      });
      setFollowedBusinesses(tempBus);
      setBusinessSelectedId(id);
    }
  };

  const getSettings = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}users/settings`
      );
      if (resp.data.status) {
        setIsEventsEnabled(resp.data.data.is_events_display);
        setIsOpeningsEnabled(resp.data.data.is_openings_display);
        setIsDealsEnabled(resp.data.data.is_deals_display);
        setSelectedCity(resp.data.data.cityId.toString());
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get settings!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchByKeyword = (arr, keyword) => {
    const [a, b] = [[], []];
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];

      if (el.business_profile) {
        if (
          el.business_profile.name.toLowerCase().includes(keyword.toLowerCase())
        ) {
          if (
            el.business_profile.name
              .toLowerCase()
              .startsWith(keyword.toLowerCase())
          )
            a.push(arr[i]);
          else b.push(arr[i]);
        }
      }
    }
    return [...a, ...b];
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    await getAllCities();
    await getSettings();
    await getAllBusinessCategories();
  }, []);

  const toggleEventsSwitch = async () => {
    setIsEventsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_events_display: !isEventsEnabled,
    });
  };

  const toggleOpeningsSwitch = async () => {
    setIsOpeningsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_openings_display: !isOpeningsEnabled,
    });
  };

  const toggleDealsSwitch = async () => {
    setIsDealsEnabled((previousState) => !previousState);
    await onEventAndOpeningsFlag({
      is_deals_display: !isDealsEnabled,
    });
  };

  const getAllBusinessCategories = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/category`
      );
      if (resp.data.status) {
        setBusinessCategories(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get business categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBusinessSubCategoriesById = async (type) => {
    try {
      if (type > 0) {
        setUnfollowBusinesses([]);
        setIsDropdownFilter(true);
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}businesses/subcategory/` + type
        );
        if (resp.data.status) {
          setBusinessSubCategories(resp.data.data);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Failed to business sub categories!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OnChangeSubCategoryGetUnfollowedBusiness = async (subCategoryId) => {
    try {
      if (subCategoryId > 0) {
        const resp = await axios.get(
          `${GlobalConstants.townTicksBaseUrl}businesses/v1/category/` +
            subCategoryId
        );
        if (resp.data.status) {
          setUnfollowBusinesses(resp.data.data);
          setIsDropdownFilter(true);
        } else {
          setUnfollowBusinesses([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SendInvite = async () => {
    if (inviteEmail) {
      try {
        if (validEmail(inviteEmail)) {
          const resp = await axios.post(
            `${GlobalConstants.townTicksBaseUrl}friend/invite`,
            {
              invitee_email: inviteEmail,
            }
          );
          if (resp.data.status) {
            toast.show({
              render: () => {
                return (
                  <Toast
                    color={GlobalStyles.colors.green}
                    message={"Invite Send Successfully!!!"}
                  />
                );
              },
              placement: "top",
            });
            setInviteEmail("");
            await getInvitedFriends();
          } else {
            toast.show({
              render: () => {
                return (
                  <Toast
                    color={GlobalStyles.colors.red}
                    message={resp.data.status_msg}
                  />
                );
              },
              placement: "top",
            });
          }
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={"Invalid Email!!!"}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Please Enter Email!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  };

  const getInvitedFriends = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}friend/invite`
      );
      if (resp.data.status) {
        setInvitedFriends(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get Invited Friends!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemindInvitedFriend = async (id) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}friend/invite/reminder`,
        {
          invitee_id: id,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Successfully reminded!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to remind!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveInvitedFriend = async (id, isFriend) => {
    let tempInvitedFriends = [];
    try {
      const resp = await axios.delete(
        `${GlobalConstants.townTicksBaseUrl}friend`,
        {
          data: {
            id: id,
            is_friend: isFriend,
          },
        }
      );
      if (resp.data.status) {
        tempInvitedFriends = invitedFriends;
        tempInvitedFriends = tempInvitedFriends.filter((obj) => {
          return obj.id !== id;
        });
        setInvitedFriends(tempInvitedFriends);
        setInvitedFriendSelectedId(id);
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Successfully removed!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to remove friend!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HStack space={3} justifyContent="center">
        <Center w={[75, 150, 220]} rounded="md">
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("City");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={cityBtnTextColor}
            >
              City
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("FollowedBusiness");
              getFollowedBusinesses();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={followedBusinessBtnTextColor}
            >
              Business{"\n"}
              Followed
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={async () => {
              changeColor("SearchAndFollow");
              await unfollowBusinessesPage1();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={searchAndFollowBtnTextColor}
            >
              Search &{"\n"}
              Follow
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("Events");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={eventsBtnTextColor}
            >
              Feeds {"\n"}
              On/Off
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("Friends");
              getInvitedFriends();
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={friendsBtnTextColor}
            >
              Friends
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="xs"
            variant={"outline"}
            my="1"
            px="1"
            shadow={3}
            onPress={() => {
              changeColor("ChangePassword");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={categoryBtnTextColor}
            >
              Change{"\n"}
              Password
            </Text>
          </Button>
        </Center>
        <Center h={360} w={[225, 400, 630]} rounded="md" shadow={3}>
          {isCitySelected ? (
            <>
              <Box>
                <FormControl>
                  <Center>
                    <Select
                      minWidth="200"
                      maxWidth="400"
                      accessibilityLabel="Choose City"
                      placeholder="Choose City"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      px="1"
                      onValueChange={(x) => {
                        setSelectedCity(x);
                      }}
                      selectedValue={selectedCity}
                    >
                      {cities.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                  </Center>
                </FormControl>
                <Center>
                  <Button
                    w={12}
                    mt="5"
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={GlobalStyles.colors.mustard}
                    borderWidth={2}
                    onPress={onSubmitCity}
                  >
                    Save
                  </Button>
                </Center>
              </Box>
            </>
          ) : (
            <></>
          )}
          {isFollowedBusinessSelected ? (
            <>
              <ScrollView>
                <Box>
                  <Heading fontSize="sm" p="4" pb="3">
                    Business Followed
                  </Heading>
                  <FlatList
                    extraData={businessSelectedId}
                    data={followedBusinesses}
                    renderItem={({ item }) => (
                      <Box
                        borderBottomWidth="1"
                        _dark={{
                          borderColor: "muted.50",
                        }}
                        borderColor="muted.300"
                        pl={["0", "4"]}
                        pr={["0", "5"]}
                        py="2"
                      >
                        <HStack space={[2, 3]} justifyContent="space-between">
                          <Avatar
                            size="sm"
                            source={{
                              uri: item.logo_url,
                            }}
                            bg={GlobalStyles.colors.blue}
                          >
                            {item.business_profile
                              ? getAvatarChar(item.business_profile?.name)
                              : ""}
                          </Avatar>
                          <VStack>
                            <Text
                              _dark={{
                                color: "warmGray.50",
                              }}
                              color="coolGray.800"
                              // numberOfLines={2}
                              bold
                            >
                              {window?.innerWidth < 415 &&
                              item.business_profile &&
                              item.business_profile?.name
                                ? item.business_profile?.name?.split(" ")[0] +
                                  // " " +
                                  // item.business_profile?.name
                                  //   ?.split(" ")[1]
                                  //   .substring(0, 5) +
                                  "..."
                                : item.business_profile?.name}
                            </Text>
                          </VStack>
                          <Spacer />

                          <Tooltip label="unfollow" openDelay={100}>
                            <Button
                              bg={GlobalStyles.colors.blue}
                              size={"sm"}
                              alignSelf="flex-start"
                              borderRadius={20}
                              onPress={() => onUnfollowBusiness(item.id)}
                            >
                              <Text
                                fontWeight="bold"
                                color={GlobalStyles.colors.white}
                              >
                                unfollow
                              </Text>
                            </Button>
                          </Tooltip>
                        </HStack>
                      </Box>
                    )}
                    keyExtractor={(item) => item.id}
                  />
                </Box>
              </ScrollView>
            </>
          ) : (
            <></>
          )}
          {isSearchAndFollowSelected ? (
            <>
              <ScrollView>
                <Box>
                  <Heading fontSize="sm" p="4" pb="3">
                    Search & Follow Business
                  </Heading>
                  <Center>
                    <Input
                      w={[180, 200, 250]}
                      placeholder="Search ..."
                      variant="filled"
                      borderRadius="10"
                      mt="1"
                      py="1"
                      px="2"
                      InputLeftElement={
                        <Icon
                          ml="2"
                          size="4"
                          color="gray.400"
                          as={<Ionicons name="ios-search" />}
                        />
                      }
                      onChangeText={onSearchTextChange}
                    />
                    <Text w={[180, 200, 250]}>
                      {noSearchBusinessResultText}
                    </Text>
                    <Select
                      w={[180, 200, 250]}
                      accessibilityLabel="Select Business Category"
                      placeholder="Select Business Category"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      borderRadius="10"
                      onValueChange={(x) => {
                        getAllBusinessSubCategoriesById(x);
                        setSelectedCategories(x);
                      }}
                      selectedValue={selectedCategory}
                    >
                      {businessCategories.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                    <Select
                      w={[180, 200, 250]}
                      accessibilityLabel="Select Business Sub Category"
                      placeholder="Select Business Sub Category"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      borderRadius="10"
                      onValueChange={(x) => {
                        OnChangeSubCategoryGetUnfollowedBusiness(x);
                        setSelectedSubCategories(x);
                      }}
                      selectedValue={selectedSubCategory}
                    >
                      {businessSubCategories.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                  </Center>
                  <FlatList
                    extraData={businessSelectedId}
                    data={unfollowBusinesses}
                    renderItem={({ item }) => (
                      <Box
                        borderBottomWidth="1"
                        _dark={{
                          borderColor: "muted.50",
                        }}
                        borderColor="muted.300"
                        pl={["0", "4"]}
                        pr={["0", "5"]}
                        py="2"
                      >
                        <HStack space={[2, 3]} justifyContent="space-between">
                          <Avatar
                            size="sm"
                            source={{
                              uri: item.logo_url,
                            }}
                            bg={GlobalStyles.colors.blue}
                          >
                            {item.business_profile
                              ? getAvatarChar(item.business_profile?.name)
                              : ""}
                          </Avatar>
                          <VStack>
                            <Text
                              _dark={{
                                color: "warmGray.50",
                              }}
                              color="coolGray.800"
                              // numberOfLines={2}
                              bold
                            >
                              {window?.innerWidth < 415 &&
                              item.business_profile &&
                              item.business_profile?.name
                                ? item.business_profile?.name?.split(" ")[0] +
                                  // " " +
                                  // item.business_profile?.name
                                  //   ?.split(" ")[1]
                                  //   .substring(0, 5) +
                                  "..."
                                : item.business_profile?.name}
                            </Text>
                          </VStack>
                          <Spacer />
                          <Tooltip label="follow" openDelay={100}>
                            <Button
                              bg={GlobalStyles.colors.blue}
                              size={"sm"}
                              alignSelf="flex-start"
                              borderRadius={20}
                              onPress={() => onFollowBusiness(item.id)}
                            >
                              <Text
                                fontWeight="bold"
                                color={GlobalStyles.colors.white}
                              >
                                follow{" "}
                              </Text>
                            </Button>
                          </Tooltip>
                        </HStack>
                      </Box>
                    )}
                    keyExtractor={(item) => item.id}
                    ListFooterComponent={renderUnfollowBusinessesFooter}
                  />
                </Box>
              </ScrollView>
            </>
          ) : (
            <></>
          )}
          {isEventSelected ? (
            <>
              <Center>
                <Heading fontSize="md">Enable Events</Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isEventsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleEventsSwitch}
                  value={isEventsEnabled}
                />
                <Heading mt="10" fontSize="md">
                  Enable Openings
                </Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isOpeningsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleOpeningsSwitch}
                  value={isOpeningsEnabled}
                />
                <Heading mt="10" fontSize="md">
                  Enable Deals
                </Heading>
                <Switch
                  size="lg"
                  mt="3"
                  defaultIsChecked={isDealsEnabled}
                  colorScheme="emerald"
                  onValueChange={toggleDealsSwitch}
                  value={isDealsEnabled}
                />
              </Center>
            </>
          ) : (
            <></>
          )}

          {friendsSelected ? (
            <>
              <Center>
                <Text fontSize="sm">
                  Invite Your friends to TownTicks & share Ticks with them.
                </Text>
              </Center>
              <Center h={110} w={[200, 300, 450]} rounded="md" shadow={3}>
                <Image
                  source={require("../../../assets/InviteLogo.png")}
                  alt="Invite"
                  size={50}
                />
                <HStack w={[150, 250, 400]}>
                  <Stack w={[100, 200, 300]}>
                    <FormControl>
                      <Input
                        type="text"
                        onChange={(x) => {
                          setInviteEmail(x.target.value);
                        }}
                        value={inviteEmail}
                        placeholder="Write Email"
                      />
                    </FormControl>
                  </Stack>
                  <Stack w={[50, 50, 100]}>
                    <FormControl>
                      <Button
                        h={36}
                        size="xs"
                        variant={"outline"}
                        borderColor={GlobalStyles.colors.mustard}
                        onPress={() => {
                          SendInvite();
                        }}
                      >
                        <Text
                          fontFamily={GlobalStyles.text.fontFamily}
                          color={GlobalStyles.colors.blue}
                          fontWeight="bold"
                        >
                          Invite
                        </Text>
                      </Button>
                    </FormControl>
                  </Stack>
                </HStack>
              </Center>
              <Center
                mt={2}
                h={200}
                w={[200, 300, 450]}
                rounded="md"
                shadow={3}
              >
                <ScrollView>
                  <Box>
                    <Heading fontSize="sm" p="4" pb="3">
                      Invited Friends
                    </Heading>
                    {invitedFriends.length > 0 ? (
                      <FlatList
                        extraData={invitedFriendSelectedId}
                        data={invitedFriends}
                        renderItem={({ item }) => (
                          <Box
                            borderBottomWidth="1"
                            _dark={{
                              borderColor: "muted.50",
                            }}
                            borderColor="muted.300"
                            pl={["0", "4"]}
                            pr={["0", "5"]}
                            py="2"
                          >
                            <HStack
                              space={[2, 3]}
                              justifyContent="space-between"
                            >
                              <VStack>
                                <Text
                                  _dark={{
                                    color: "warmGray.50",
                                  }}
                                  color="coolGray.800"
                                >
                                  {item.isFriend
                                    ? window?.innerWidth < 415 &&
                                      item.friend &&
                                      item.friend.citizen_profile.name &&
                                      item.friend.citizen_profile.name
                                      ? truncateString(
                                          item.friend.citizen_profile.name,
                                          10
                                        )
                                      : truncateString(
                                          item.friend.citizen_profile.name
                                        )
                                    : window?.innerWidth < 415 && item.email
                                    ? truncateString(item.email, 10)
                                    : truncateString(item.email)}
                                </Text>
                              </VStack>
                              <Spacer />
                              {item.isFriend ? (
                                <>
                                  <MaterialIcons
                                    name="group"
                                    size={24}
                                    color={GlobalStyles.colors.blue}
                                  />
                                </>
                              ) : window?.innerWidth < 415 ? (
                                <>
                                  <MaterialIcons
                                    name="notifications-none"
                                    size={24}
                                    color={GlobalStyles.colors.blue}
                                    onPress={() =>
                                      onRemindInvitedFriend(item.id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <Tooltip label="Remind" openDelay={100}>
                                    <Button
                                      size={"xxs"}
                                      px={1}
                                      alignSelf="flex-start"
                                      borderRadius={5}
                                      variant={"outline"}
                                      borderColor={GlobalStyles.colors.mustard}
                                      borderWidth={1}
                                      onPress={() =>
                                        onRemindInvitedFriend(item.id)
                                      }
                                    >
                                      <Text
                                        color={GlobalStyles.colors.blue}
                                        fontWeight={"bold"}
                                      >
                                        Remind
                                      </Text>
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                              <Tooltip label="Remove" openDelay={100}>
                                <Button
                                  size={"xxs"}
                                  px={1}
                                  alignSelf="flex-start"
                                  borderRadius={5}
                                  variant={"outline"}
                                  borderColor={GlobalStyles.colors.mustard}
                                  borderWidth={1}
                                  onPress={() =>
                                    onRemoveInvitedFriend(
                                      item.id,
                                      item.isFriend
                                    )
                                  }
                                >
                                  <Text
                                    color={GlobalStyles.colors.blue}
                                    fontWeight={"bold"}
                                  >
                                    Remove
                                  </Text>
                                </Button>
                              </Tooltip>
                            </HStack>
                          </Box>
                        )}
                        keyExtractor={(item) => item.id}
                      />
                    ) : (
                      <>
                        <Center mt={5}>
                          <Text>No Record Found</Text>
                        </Center>
                      </>
                    )}
                  </Box>
                </ScrollView>
              </Center>
            </>
          ) : (
            <></>
          )}

          {changePasswordSelected ? <ChangePassword /> : <></>}
        </Center>
      </HStack>
    </>
  );
};

export default CitizenSettingsPage;
